import { staticWithSiteData, withErrorHandling } from '@common/utils/page';
import { SiteData } from '@interfaces';

import ErrorPage from './_error';

const NotFoundPage = () => {
	return <ErrorPage statusCode={404} />;
};

export const getStaticProps = withErrorHandling<SiteData>(
	staticWithSiteData(async () => {
		return {
			props: {},
		};
	}),
);

export default NotFoundPage;
